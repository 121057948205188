<template>
  <el-container style="height:calc(100vh);">
    <el-header class="header">
      <div class="top">
        <div class="left el-icon-arrow-left" @click="returnOn"></div>
        <el-input class="searchInput" v-model="searchVal" placeholder="请输入会员姓名、备注名、编号或手机" prefix-icon="el-icon-search"
          clearable @keyup.enter.native="searchChange">
        </el-input>
        <div class="right iconfont icon-shaixuan" @click="screeningShow=true">
          筛选
        </div>
      </div>
      <div class="bottom">
        <div class="thead">
          <div class="th th1">姓名</div>
          <div class="th th2">账单编号</div>
          <div class="th th3">结算时间</div>
          <div class="th th4">收银人员</div>
          <div class="th th5">消费项目</div>
          <div class="th th6">实付金额</div>
          <div class="th th7">操作</div>
        </div>
      </div>
    </el-header>
    <el-main>
      <div class="tbody">
        <div class="tr" v-for="(item,i) in 20" :key="i">
          <div class="td td1">
            <div class="avatar">
              <img src="@/assets/images/avatar.png" alt="">
            </div>
            <div class="name-tel">
              <div class="name">赵晓梅{{i}}</div>
              <div class="tel">18088880000</div>
            </div>
          </div>
          <div class="td td2">ZD202101016600</div>
          <div class="td td3">2021/01/03 12:00</div>
          <div class="td td4">顾小敏</div>
          <div class="td td5">共5项</div>
          <div class="td td6"><b>¥ 399.00</b></div>
          <div class="td td7">
            <div class="btn">补打小票</div>
            <div class="btn" @click="jumpServiceBills">账单详情</div>
          </div>
        </div>
      </div>
    </el-main>
    <el-drawer :show-close="false" :visible.sync="screeningShow" direction="rtl" :before-close="handleClose">
      <template slot="title">
        <div class="drawer-title">
          <div class="left"></div>
          <div class="center">账单筛选</div>
          <div class="right el-icon-close" @click="screeningShow = false"></div>
        </div>
      </template>
      <el-container style="height:calc(100vh - 52px);">
        <el-main>
          <div class="type">
            <div class="title">
              账单类型
            </div>
            <div class="box">
              <div :class="typeCheck===0?'list list1':'list'" @click="typeCheck=0">全部账单</div>
              <div :class="typeCheck===1?'list list1':'list'" @click="typeCheck=1">服务账单</div>
              <div :class="typeCheck===2?'list list1':'list'" @click="typeCheck=2">积分账单</div>
              <div :class="typeCheck===3?'list list1':'list'" @click="typeCheck=3">充值账单</div>
              <div :class="typeCheck===4?'list list1':'list'" @click="typeCheck=4">退货账单</div>
            </div>
          </div>
          <div class="time">
            <div class="title">
              账单类型
            </div>
            <div class="box">
              <div :class="timeCheck===0?'list list1':'list'" @click="timeCheck=0">全部</div>
              <div :class="timeCheck===1?'list list1':'list'" @click="timeCheck=1">本周内</div>
              <div :class="timeCheck===2?'list list1':'list'" @click="timeCheck=2">本月内</div>
              <div :class="timeCheck===3?'list list1':'list'" @click="timeCheck=3">半年内</div>
              <div :class="timeCheck===4?'list list1':'list'" @click="timeCheck=4">一年内</div>
            </div>
          </div>
          <div class="check-time">
            <div class="title">
              选择时间
            </div>
            <div class="time-ipt">
              <el-date-picker v-model="checkTime" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </div>
        </el-main>
        <el-footer>
          <div class="btn" @click="reset">重置</div>
          <div class="btn" @click="confirm">完成</div>
        </el-footer>
      </el-container>
    </el-drawer>
  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        searchVal: '',
        screeningShow: false,
        typeCheck: 0,
        timeCheck: 0,
        checkTime: ''
      }
    },
    methods: {
      returnOn() {
        this.$router.push({
          name: 'StaffDetail'
        })
      },
      jumpServiceBills() {
        window.sessionStorage.setItem('reservationPath', this.$route.path)
        this.$router.push({
          name: 'ServiceBills'
        })
      },
      // 搜索
      searchChange() {
        if (this.searchVal.trim()) {
          console.log(this.searchVal.trim());
        } else {
          this.$message.error('搜索内容不能为空')
        }
      },
      reset() {
        this.typeCheck = 0
        this.timeCheck = 0
        this.checkTime = ''
      },
      confirm() {
        this.screeningShow = false
      },
      handleClose() {
        this.screeningShow = false
      },
    }
  }

</script>

<style lang="less" scoped>
  .header {
    height: auto !important;
    padding: 0;
    box-sizing: border-box;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      box-sizing: border-box;
      border-bottom: 1px solid #e9e9e9;
      padding: 0 15px;


      .left {
        font-size: 24px;
        color: #409eff;
      }

      .el-input {
        width: 50%;

        /deep/ .el-input__inner {
          border-radius: 50px;
        }
      }

      .right {
        font-size: 14px;
        color: #409eff;

        &::before {
          margin-right: 5px;
        }
      }
    }

    .bottom {
      background-color: #f5f5f5;
      padding: 0 10px;
      border-bottom: 1px solid #e9e9e9;

      .thead {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 40px;
        box-sizing: border-box;
        font-size: 12px;

        .th {
          text-align: center;
          width: 10%;
        }

        .th1 {
          width: 20%;
        }

        .th2,
        .th3 {
          width: 15%;
        }

        .th4 {
          width: 8%;
        }

        .th7 {
          width: 22%;
        }
      }
    }
  }

  .el-main {
    background-color: #f9f9f9;
  }

  .tbody {
    font-size: 12px;

    .tr {
      display: flex;
      justify-content: space-around;
      align-items: center;
      background-color: #fff;
      border: 1px solid #f2f2f2;
      margin-bottom: 20px;
      padding: 10px 0;

      &:first-child {
        margin-top: 20px;
      }

      .td {
        text-align: center;
        width: 10%;
      }

      .td1 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20%;

        .avatar {

          img {
            width: 35px;
            margin-right: 10px;
          }
        }

        .name-tel {
          .name {
            text-align: left;
          }
        }
      }



      .td2,
      .td3 {
        width: 15%;
      }

      .td4 {
        width: 8%;
      }


      .td7 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22%;

        .btn {
          width: 70px;
          border: 1px solid #409eff;
          line-height: 30px;
          border-radius: 40px;
          color: #409eff;
          font-size: 12px;

          &:first-child {
            margin-right: 5px;
          }

          &:last-child {
            background-color: #409eff;
            color: #fff;
          }
        }
      }
    }
  }

  /deep/ .el-drawer {
    min-width: 400px;

    .el-drawer__header {
      padding: 0;
      margin-bottom: 0;
    }

    .drawer-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f2f2f2;
      padding: 15px;

      .center {
        font-weight: bold;
        font-size: 16px;
        color: #333;
      }

      .right {
        font-size: 18px;
        font-weight: bold;
        color: #409eff;
      }
    }

    .el-main {
      background-color: #fff;

      .type,
      .time {
        .title {
          line-height: 50px;
          padding-left: 15px;
        }

        .box {
          padding: 0 20px;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          .list {
            box-sizing: border-box;
            margin-bottom: 10px;
            margin-right: 2%;
            width: 23%;
            text-align: center;
            line-height: 30px;
            color: #666;
            font-size: 12px;
            border: 1px solid #f2f2f2;
          }

          .list1 {
            color: #409eff;
            border: 1px solid #409eff;
            background-color: #f6faff;
          }
        }
      }

      .check-time {
        .title {
          line-height: 50px;
          padding-left: 15px;
        }
      }

      .time-ipt {
        padding: 0 20px;

        .el-date-editor--daterange.el-input,
        .el-date-editor--daterange.el-input__inner,
        .el-date-editor--timerange.el-input,
        .el-date-editor--timerange.el-input__inner {
          width: 100%;
        }
      }
    }

    .el-footer {
      border-top: 1px solid #f2f2f2;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .btn {
        width: 40%;
        border: 1px solid #409eff;
        text-align: center;
        line-height: 40px;
        border-radius: 40px;
        color: #409eff;

        &:last-child {
          background-color: #409eff;
          color: #fff;
        }
      }
    }
  }

</style>
